import React, { Fragment } from "react";
import "./Independence.css";
import { Helmet } from "react-helmet-async";

export default function NewYear() {
  return (
    <Fragment>
      <Helmet>
        <title>Keture | The 77th National Independence Day</title>
        <meta name="title" content="Keture | About" />
        <meta
          name="description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | About" />
        <meta
          property="og:description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | About" />
        <meta
          property="twitter:description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <section className="leading-relaxed mx-auto px-4 lg:px-8 py-10 sm:py-20 min-h-screen flex flex-col justify-center align-middle">
        <div className="space-y-3 text-center">
          <div>
            <div className="containerInd">
              <div className="flag">
                <div className="bar bar-1"></div>
                <div className="bar bar-2"></div>
                <div className="bar bar-3"></div>
                <div className="bar bar-4"></div>
                <div className="bar bar-5"></div>
                <div className="bar bar-6"></div>
                <div className="bar bar-7"></div>
                <div className="bar bar-8"></div>
                <div className="bar bar-9"></div>
                <div className="bar bar-10"></div>
                <div className="bar bar-11"></div>
                <div className="bar bar-12"></div>
                <div className="bar bar-13"></div>
                <div className="bar bar-14"></div>
                <div className="bar bar-15"></div>
                <div className="bar bar-16"></div>
                <div className="bar bar-17"></div>
                <div className="bar bar-18"></div>
                <div className="bar bar-19"></div>
                <div className="bar bar-20"></div>
              </div>
            </div>
          </div>
          <h1 className="text-4xl sm:text-5xl text-gray-100 font-black max-w-4xl mx-auto pt-10">
          The 77th National Independence Day!
          </h1>
          <p className="text-gray-200  max-w-4xl mx-auto text-xl">
          Today, as we celebrate this historic occasion, we do so with renewed hopes and aspirations for a new kind of freedom—one that empowers progress, unity, and a brighter future for all.
          </p>

          <p className="sm:text-lg">
            Warm wishes from the{" "}
            <span className="text-lg sm:text-xl font-black bg-gradient-to-r from-yellow-500 via-orange-500 to-red-500 text-transparent bg-clip-text">
              Keture
            </span>{" "}
            team.
          </p>
        </div>
      </section>
    </Fragment>
  );
}
